import React from 'react';


const Bliss_performance = () => (
    <div className="content">
        <h1>Bliss & the Masquerade Performance</h1>

        <div className="slide">
            <img src={"https://lordtophstorage.blob.core.windows.net/web/projects/5_bliss_performance/16. Bliss & the Masquerade Halloween Event Invitation.png"} alt={"Bliss and the Masquerade Invitation"}/>
        </div>


<div className={"about-text"}>

    </div>
    </div>
);


export default Bliss_performance;